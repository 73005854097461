import React from 'react';
import { ReactComponent as Upload } from '../../assets/images/upload.svg';
import { ReactComponent as Watch } from '../../assets/images/watch.svg';
import background from '../../assets/images/attivita-monopattino-inside.png';
import { Box, Button, Flex, Text } from '../../components/Ui';
import Layout from './Layout';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Monopattino() {
  const userType = useSelector(state => state.user.user?.userinfo?.type);

  return (
    <Layout banner={background}>
      <Box shadow>
        <Flex gap={5} align='center'>
          <Watch />
          <Text color='#6E6E6E'>Aggiornato il {new Date().toLocaleDateString()}</Text>
        </Flex>
      </Box>
      <Box shadow className='mt-32'>
        <Text bold size={30}>
          {userType === 'installatore'
            ? 'A giugno, prova a vincere un monopattino elettrico LEXGO'
            : 'A giugno, in palio 4 monopattini elettrici LEXGO'}
        </Text>
        <Text className='mt-12'>
          {userType === 'installatore'
            ? 'Dal 1° al 30 giugno 2024 acquista i prodotti in promozione raggiungendo almeno il valore di listino di 1.500,00€ (esclusa iva) e carica i DDT per partecipare all’estrazione di 4 monopattini elettrici LEXGO.'
            : 'Dal 1° al 30 giugno 2024 ricorda ai tuoi clienti che acquistando prodotti in promozione per un valore di listino di almeno 1.500,00€ (esclusa iva) e caricando i DDT parteciperanno all’estrazione di 4 monopattini elettrici LEXGO.'}
        </Text>
        {userType === 'installatore' ? (
          <Link to='/load-ddt'>
            <Button active className='mt-24' upper>
              Carica DDT
              <Upload />
            </Button>
          </Link>
        ) : (
          <Link to='/user/profile'>
            <Button active className='mt-24' upper>
              DDT a te associati
            </Button>
          </Link>
        )}
      </Box>
    </Layout>
  );
}

export default Monopattino;
