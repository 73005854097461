import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '../../components/Fade';
import Form from '../../components/Games/Form';
import { getGame, getGames } from '../../store/actions';

function FormWin() {
  const dispatch = useDispatch();
  const isLogged = useSelector(state => state.auth.token);
  const wins = useSelector(state => state.user.user?.wins || []);
  const lang = useSelector(state => state.app.config?.lang);
  const { game } = useSelector(state => state.promo || {});
  const { log, played, form } = game.length > 0 ? game[0] : {};

  useEffect(() => {
    dispatch(getGame(wins[0]?.game_id, 'promo-carrera-ducati', ''));
  }, []);
  return (
    <Fade>
      {game.length > 0 && (
        <Form
          form={form}
          formResponse={log.form}
          lang={lang}
          id={wins[0]?.game_id}
          played={played}
          formWin
          playslog={log.playslog}
        />
      )}
    </Fade>
  );
}

export default FormWin;
