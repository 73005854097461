import { Delete } from '@styled-icons/material-twotone';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Text } from '../../../Ui';

const ImagePreviewContainer = styled.div`
  overflow: hidden;
  width: 100%;
  background-color: #e4eaef;
  border-radius: 10px;
  position: relative;
  display: ${props => (props.pdf ? 'flex' : props.show ? 'inline-block' : 'none')};
  ${({ pdf }) =>
    pdf &&
    css`
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;
      height: 200px;
    `}
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const ImagePreview = styled.div`
  display: flex;
  justify-content: center;
`;

const DeleteContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #a3aaae;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadButton = styled(Button)`
  display: ${props => (props.show ? 'inline-block' : 'none')};
  border: 2px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : ''};
`;

const File = props => {
  const {
    setImage,
    setFormValid,
    formSetSelectedFiles,
    resetFormHandler,
    resetImage,
    setResetImage,
  } = props;
  const upRef = useRef(null);

  const [imagePreviewUrl2, setImgPreviewUrl2] = useState(null);
  const [pdfPreviewUrl2, setPdfPreviewUrl2] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdf, setPdf] = useState(false);

  const fileUploadHandler2 = event => {
    event.preventDefault();
    setResetImage(false);
    const file = event.target.files[0];

    if (file !== undefined) {
      setSelectedFile({
        name: event.target.name,
        file: event.target.files[0],
      });

      setFormValid(event);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type !== 'application/pdf') {
          setImgPreviewUrl2(reader.result);
          setImage(true);
        } else {
          setPdf(true);
          setImage(true);
        }
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setImgPreviewUrl2(null);
    }
  };

  const deleteFileHandler2 = () => {
    setImgPreviewUrl2(null);
    setImage(false);
    setPdfPreviewUrl2(null);
    setPdf(false);
    // resetFormHandler();
    setSelectedFile(null);
  };

  useEffect(() => {
    if (selectedFile) {
      formSetSelectedFiles(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (resetImage) {
      setImgPreviewUrl2(null);
      setImage(false);
      setPdf(false);
      setSelectedFile(null);
    }
  }, [resetImage]);

  return (
    <>
      <ImagePreviewContainer show={imagePreviewUrl2 || pdf} pdf={pdf}>
        {pdf ? (
          <>
            <Text>Name: {selectedFile?.file?.name}</Text>
            <Text>Type: {selectedFile?.file?.type}</Text>
            <Text>Size: {(+selectedFile?.file?.size / (1024 * 1024)).toFixed(2)}(MB)</Text>
          </>
        ) : (
          <ImagePreview>{imagePreviewUrl2 && <img src={imagePreviewUrl2} alt='' />}</ImagePreview>
        )}
        <DeleteContainer onClick={deleteFileHandler2}>
          <Delete size={23} />
        </DeleteContainer>
      </ImagePreviewContainer>

      <input
        style={{ display: 'none' }}
        ref={upRef}
        {...props.elementConfig}
        accept='.pdf, .jpg, .png'
        onChange={fileUploadHandler2}
      />
      <UploadButton
        {...props}
        fullwidth
        active
        upper
        onClick={() => upRef.current.click()}
        show={imagePreviewUrl2 ? !imagePreviewUrl2 : pdf ? !pdf : true}
      >
        {props.elementConfig.placeholder}
      </UploadButton>
    </>
  );
};

export default File;
