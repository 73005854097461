import React, { useState } from 'react';
import useForm from '../../../hooks/useForm';
import { Input, Button, Wrapper, Loader, Text, Modal, Col, Box, Flex } from '../../Ui';
import Fade from '../../Fade';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import customaxios from '../../../config/axios-refresh-token';
import { getSlug } from '../../../utils/utils';
import { connect, useDispatch } from 'react-redux';
import InputField from '../../Ui/Input/InputField/InputField';
import * as actionCreators from '../../../store/actions';
import moment from 'moment';

const Response = styled.div`
  text-align: center;
`;

const Extra = styled.p`
  font-size: 20px;
`;

const Form = props => {
  const {
    form: {
      formId,
      data,
      type,
      i18l: { title, content, extra },
    },
    lang,
    id,
    playslog,
    formResponse,
    played,
    checkFormCompiled,
    buttonAlign,
    userInfo,
    game_cms,
    formWin,
  } = props;

  let showResponse = false;
  if (formResponse !== null && played) {
    showResponse = true;
  }

  const [loading, setLoading] = useState(false);
  const [showFormResponse, setShowFormResponse] = useState(showResponse);
  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState('');
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const dataForm = JSON.parse(data);

  let slug = getSlug();

  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const submitHandler = e => {
    const objData = {};
    for (const property in formData) {
      objData[property] = formData[property].value;
    }

    const data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === 'fileextra') {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    data.append('formId', formId);
    data.append('data', JSON.stringify(objData));
    data.append('section', 'form');
    if (type === 'post') {
      data.append('playslog', playslog);
    }
    setLoading(true);
    customaxios
      .post(`game/promo-carrera-ducati/${id}/data`, data)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          if (response.data.data.status) {
            setShowFormResponse(true);
          } else {
            setShowModal(true);
            setInfo(response.data.data.info);
          }
          checkFormCompiled(formId);
          dispatch(actionCreators.getUserInfo());
        }
      })
      .catch(error => {
        setLoading(false);
        setShowModal(true);
        setInfo('errore interno');
      });
  };

  const closeModal = () => {
    setInfo('');
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === 'datepicker') {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    setFormValid,
    imgPreviewUrl,
    districts,
    cities,
    provinces,
    zipcode,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        readonly={inp.readonly}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        inModal={true}
        radioInputs={inp.inputs}
        setImage={setImage}
        setFormValid={setFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        provinces={provinces}
        {...inp}
      />
    );
  });

  const cms_text = game_cms[0].contents.find(e => e.name === 'game_validation_text');

  return (
    <>
      {showFormResponse ? (
        <Fade show={true}>
          <Response>
            <Text primary size={34} align='center' className='mb-20'>
              {extra || 'Grazie per aver compilato il form'}
            </Text>
            <Link to='/'>
              <Button secondary>Torna alla homepage</Button>
            </Link>
          </Response>
        </Fade>
      ) : (
        <Flex row={15} align='stretch'>
          {inputs[7].props.value === '1' && (
            <Col width={33.333} className='md_mb-20'>
              <Box style={{ paddingBottom: 0 }}>
                {/*  {!formWin && (
                <>
                  <Text primary size={34} className='mb-20'>
                    {cms_text?.i18l.title}
                  </Text>
                  <Text className='mb-30'>{cms_text?.i18l.content}</Text>
                  <Text size={24} className='mb-20'>
                    Documento d'identità
                  </Text>
                </>
              )} */}
                {inputs.slice(0, 7)}
              </Box>
            </Col>
          )}

          <Col width={66.666}>
            <Box style={{ paddingBottom: 0 }}>
              <Text size={24}>Dati personali e convalida premio</Text>
              {/*               <PersonalDataBox>
              <div className='content'>
                  <Flex row={15} wrap='wrap'>
                    <Col width={50} className='mb-20'>
                      <InputField value={userInfo.firstname} disabled />
                    </Col>
                    <Col width={50} className='mb-20'>
                     <InputField value={userInfo.lastname} disabled />
                    </Col>
                    <Col width={50} className='mb-20'>
                     <InputField value={userInfo.email} disabled />
                    </Col>
                    <Col width={50} className='mb-20'>
                      <InputField value={userInfo.phone} disabled />
                    </Col>
                  </Flex>
                </div> 
              </PersonalDataBox> */}
              {inputs[7]}

              {inputs[7].props.value === '1' && (
                <>
                  <Text className='mt-30 mb-20' size={24}>
                    Indirizzo di spedizione
                  </Text>
                  <Flex row={15} wrap='wrap'>
                    {inputs.slice(8).map((input, inputIndex) => (
                      <Col width={50} key={inputIndex}>
                        {input}
                      </Col>
                    ))}
                  </Flex>
                </>
              )}
            </Box>

            <Box primary className='mt-30' style={{ padding: 15 }}>
              <Flex justify='center'>
                <Button
                  loading={loading}
                  white
                  style={{ minWidth: 300 }}
                  onClick={formSubmitHandler}
                >
                  Invia
                </Button>
              </Flex>
            </Box>
          </Col>
        </Flex>
      )}

      {/* <Wrapper>
        {!showFormResponse ? (
          <Fade show={true}>
            <Container>
              {title && <Title>{title}</Title>}
              {content && <Content>{content}</Content>}
              <form onSubmit={formSubmitHandler}>
                {inputs}
                <ButtonContainer buttonAlign={buttonAlign}>
                  <Button type='submit' disabled={loading}>
                    {loading ? <Loader inBtn show={true} /> : t('auth.send')}
                  </Button>
                </ButtonContainer>
              </form>
            </Container>
          </Fade>
        ) : (
          <Response>
            {extra ? <Extra>{extra}</Extra> : <Extra>{t('games.formcompleted')}</Extra>}
          </Response>
        )}
        {showModal ? (
          <Modal show={showModal} close={closeModal}>
            <p>{info && info}</p>
          </Modal>
        ) : null}
      </Wrapper> */}
      <Modal show={showModal} close={closeModal}>
        <p>{info && info}</p>
      </Modal>
    </>
  );
};

const PersonalDataBox = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;

  strong {
    font-size: 12px;
    color: ${props => props.theme.primary};
    text-transform: uppercase;
  }

  .content {
    //background-color: ${props => props.theme.primary};
    padding: 15px;
    margin: 0 -15px;
  }
`;

const mapStateToProps = state => {
  return {
    getCheckFormCompiled: state.game.checkFormCompiled,
    userInfo: state.user.user.userinfo,
    game_cms: state.cms.game,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    checkFormCompiled: formId => dispatch(actionCreators.checkFormCompiled(formId)),
  };
};

// export default Form;
export default connect(mapStateToProps, mapDispatchToProps)(Form);
