import React from 'react';
import { ReactComponent as Upload } from '../../assets/images/upload.svg';
import { ReactComponent as Watch } from '../../assets/images/watch.svg';
import background from '../../assets/images/attivita-maggio-background.png';
import { Box, Button, Flex, Text } from '../../components/Ui';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { useSelector } from 'react-redux';

function Citofonia() {
  const userType = useSelector(state => state.user.user?.userinfo?.type);

  return (
    <Layout banner={background}>
      <Box shadow>
        <Flex gap={5} align='center'>
          <Watch />
          <Text color='#6E6E6E'>Aggiornato il {new Date().toLocaleDateString()}</Text>
        </Flex>
      </Box>
      <Box shadow className='mt-32'>
        <Text bold size={30}>
          Con la citofonia ABB, a maggio raddoppiano i punti!
        </Text>
        <Text className='mt-12'>
          {userType === 'installatore'
            ? 'Nel periodo 6-26 maggio 2024 acquista i prodotti della classe commerciale BLD10B20 “Welcome M: Soluzioni per impianti di citofonia e videocitofonia” e carica i tuoi DDT per maturare 2 punti ogni 5€, invece di 1 punto ogni 5€.'
            : 'Nel periodo 6-26 maggio 2024 ricorda ai tuoi clienti che acquistando i prodotti della classe commerciale BLD10B20 “Welcome M: Soluzioni per impianti di citofonia e videocitofonia” e caricando i DDT maturano 2 punti ogni 5€.'}
        </Text>
        {userType === 'installatore' ? (
          <Link to='/load-ddt'>
            <Button active className='mt-24' upper>
              Carica DDT
              <Upload />
            </Button>
          </Link>
        ) : (
          <Link to='/user/profile'>
            <Button active className='mt-24' upper>
              DDT a te associati
            </Button>
          </Link>
        )}
      </Box>
    </Layout>
  );
}

export default Citofonia;
