import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Carousel from '../../components/Ui/Carousel/Carousel';
import { Link } from 'react-router-dom';
import { Box, Button } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import bgHeaderMobile from '../../assets/images/bg-header-mobile.png';
import bgHeader from '../../assets/images/bg-header.png';
import bgDownload from '../../assets/images/bg-download.png';
import bgDownloadMobile from '../../assets/images/bg-download-mobile.png';
import appIos from '../../assets/images/app-ios.svg';
import googlePlay from '../../assets/images/app-google.svg';
import iconPoint1 from '../../assets/images/icon-points-1.svg';
import iconPoint2 from '../../assets/images/icon-points-2.svg';
import iconPoint3 from '../../assets/images/icon-points-3.svg';
import iconPoint4 from '../../assets/images/icon-points-4.svg';
import prizes1 from '../../assets/images/prizes-1.png';
import prizes2 from '../../assets/images/prizes-2.png';
import prizes3 from '../../assets/images/prizes-3.png';
import prizes4 from '../../assets/images/prizes-4.png';
import prizes5 from '../../assets/images/prizes-5.png';
import lottery1 from '../../assets/images/lottery-1.png';
import lottery2 from '../../assets/images/lottery-2.png';
import lottery3 from '../../assets/images/lottery-3.png';
import lottery4 from '../../assets/images/lottery-4.png';
import lottery5 from '../../assets/images/lottery-5.png';
import lottery5mobile from '../../assets/images/lottery-5-mobile.png';
import logoABB from '../../assets/images/Logo-ABB.svg';
import arrowTop from '../../assets/images/icon-arrow-top.svg';
const Container = styled.div`
  height: 100vh;
  margin: 0 16px;
  .container-full {
    max-width: 1140px;
    padding: 0 16px;
    position: relative;
    margin: 0 auto;
  }

  .container-short {
    max-width: 1108px;
    padding: 16px;
    margin: 0 auto;
    position: relative;
  }

  .header-content {
    margin-top: 0;

    ${respondTo.md`
      margin-top: 60px;
    `}

    .header-btn-registration-wrapper {
      margin-top: 20px;

      ${respondTo.md`
        margin-top: 60px;
      `}

      button {
        width: 220px;
      }
    }
  }

  .btn-registration-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    button {
      width: 220px;
    }
  }

  .no-mobile {
    display: none !important;
    ${respondTo.md`
    display: initial!important;
    &.slider {
        display: flex!important ;
    }
  
         `}
  }
  .no-desktop {
    display: initial;
    &.carousel-landing {
      display: block;
    }
    ${respondTo.md`
    display: none !important;
    `}
  }

  small {
    color: #ff000f;
    position: relative;
    top: -5px;
  }

  .header {
    background-image: url(${bgHeaderMobile});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 265px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    border-radius: 14px;
    padding-top: 45px;
    ${respondTo.sm`
        display: flex;
        flex-direction:row;
        align-items: center;
        justify-content: flex-start;
        gap:0;  
        padding-top: 0;
         `}

    /* & > div {
      width: 100%;
    } */

    .download-app-header {
      img {
        width: 120px;
      }
      display: flex;
      ${respondTo.sm`
        position: absolute;
        top: 30px;
        left: 20px;
        display: initial;
        img {
            width: 150px;
        }
        
           `}
      a {
        max-width: 150px;
        margin-right: 20px;
      }
    }
    ${respondTo.sm`
    height: 461px;
    background-image: url(${bgHeader});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 22px;
         `}

    & > img {
      width: auto;

      height: 22px;
      top: 21px;

      position: absolute;

      right: 32px;
      ${respondTo.sm`
     height: 42px;
     top: 32px;
           `}
    }

    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      span {
        color: #ff000f;
        display: block;
      }
      ${respondTo.sm`
      font-size: 46px;
      line-height: initial;
           `}
    }
  }

  .description {
    background-color: #fff;
    box-shadow: 0px 6px 24px #0000001a;
    border-radius: 14px;
    margin-bottom: 32px;
    ${respondTo.sm`
    margin-bottom: 23px;
         `}

    & > p > a {
      color: #ff000f;
      text-decoration: underline;
    }

    h4 {
      font-size: 18px;
      line-height: 23px;
      font-weight: bold;
      ${respondTo.sm`
       font-size: 24px;
      line-height: 30px;
           `}

      span {
        color: #ff000f;
        display: block;
      }
    }

    p {
      color: #000;
      font-size: 16px;
      line-height: 24px;
      margin: 12px 0 16px;
    }
    .slick-slider .slick-slide > div {
      padding: 0 16px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .carousel-landing .slick-arrow {
      display: none !important;
    }
    .points {
      background: #f0f0f0;
      border-radius: 8px;
      padding: 24px 16px;
      margin-bottom: 24px;

      display: flex;
      flex-direction: column;
      gap: 32px;
      ${respondTo.sm`
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 40px 32px;
      justify-content: start;
      align-content: start;
      justify-items: flex-start;
      align-items: start;
      
           `}

      &--item {
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: 12px;

        img {
          height: auto;
          width: 35px;
          object-fit: contain;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: start;

          h4 {
            font-size: 22px;
            line-height: normal;
            font-weight: bold;
            margin-bottom: 8px;
            ${respondTo.sm`
            margin-bottom: 0;
                 `}
          }

          p {
            font-size: 15px;
            margin: 0;
          }
        }
      }
    }
    .carousel-landing {
      background: #f0f0f0;
      border-radius: 8px;
      > .slick-slider {
        overflow: hidden;
      }
    }
    .prizes {
      padding: 16px 0 30px;
      ${respondTo.md`
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
           `}

      &--item {
        width: 180px !important;
        height: 144px !important;
        object-fit: cover;
      }
    }

    .lottery {
      background: #f0f0f0;
      padding: 16px 0 40px;
      border-radius: 8px 8px 0 0;
      ${respondTo.md`
     
     display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      border-radius: 8px;
           `}

      &.no-slider {
        display: initial;
        ${respondTo.md`
      
        display: none;
           `}
      }

      &.slider {
        display: none;
        ${respondTo.md`
        display: flex;
           `}
      }

      &--item {
        border-radius: 8px;
        width: 180px !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.for-slider {
          display: flex;
          ${respondTo.md`
           display: none;
             `}
        }

        ${respondTo.md`
        width: 212px !important;
           `}

        img {
          width: 180px;
          height: 144px;
          object-fit: cover;
        }

        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px;
          line-height: 18px;
          height: 56px;
        }

        h6 {
          text-align: center;
          font-size: 16px;
          line-height: 18px;
          height: 44px;
          display: flex;
          align-items: flex-end;
          padding-bottom: 8px;
          color: #fff;
        }
      }

      &--item-special {
        background-color: #ff000f;
        border-radius: 0 0 8px 8px;
        width: 100% !important;
        padding: 16px;
        ${respondTo.md`
         border-radius: 8px;
         width: 212px !important;
         
           `}

        h6 {
          height: unset;
          ${respondTo.md`
          height: unset;
               `}
        }

        img {
          object-fit: contain;
        }

        p {
          color: #fff;
          height: unset;
          font-weight: 300;
          ${respondTo.lg`
          
             `};
        }
      }
    }

    .slick-slide .slide {
      z-index: 1;
      transform: scale(1);
      opacity: 1;
    }

    .slick-slide {
      padding: 0 16px;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;

    background-color: transparent;

    li {
      margin: 0 0.25rem;
      background-color: transparent;
    }

    button {
      display: block;
      width: 6px;
      height: 6px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background: #d2d2d2;
      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: #ff000f;
      width: 26px;
      border-radius: 5pt;
    }
  }

  .restrictions {
    font-size: 10px;
    line-height: 13px;
    font-weight: 100;
    color: #262626;
    padding: 0;
    margin-bottom: 32px;
    ${respondTo.sm`
    font-weight: bold;
       `}

    a {
      color: #ff000f;
      text-decoration: underline;
    }
  }

  .download {
    margin-bottom: 60px;

    height: 313px;
    background-image: url(${bgDownloadMobile});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;

    ${respondTo.sm`
  
    height: 275px;
    background-image: url(${bgDownload});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-right: 46px;
    padding-left: calc(100% - 85%);
    display: flex;
    align-items: flex-start;
    justify-content: center;
     margin-bottom: 60px;
       `}

    ${respondTo.xs`
    align-items: center;
        `}
    

    .download-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      ${respondTo.sm`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
          `}
    }

    h1 {
      font-weight: bold;

      font-size: 27px;
      margin: 0 0 16px 0;

      ${respondTo.xs`
         font-size: 29px;
      margin-right: 30px;
        `}
      ${respondTo.sm`
         font-size: 34px;
            margin-right: 40px;
        `}
        ${respondTo.md`
           font-size: 37px;
        margin: 16px 0;
        margin-right: 40px;
              `}
        ${respondTo.lg`
             font-size: 46px;
              margin-right: 40px;
          `}
    }

    &-app {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      ${respondTo.sm`
        gap: 16px;
        display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;
            `}

      a {
        max-width: 199px;
        width: 100%;
        height: 59px;
        object-fit: contain;
        ${respondTo.md`
        max-width: unset;
        width: auto;
        height: 39px;
            `}

        ${respondTo.sm`
        max-width: unset;
        width: auto;
        height: 49px;
        `}

        img {
          width: 165px;
          height: auto;
          ${respondTo.sm`
          width: 100%;
          height: 100%;
          `}
        }
      }
    }
  }

  .email-contact {
    text-align: center;
    margin-bottom: 60px;
    font-size: 20px;

    a {
      color: #ff000f;
    }
  }

  .footer {
    padding: 0;

    &-top {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 74px;
      padding: 16px 0;
      border-radius: 14px 14px 0 0;
      position: relative;

      img {
        height: 32px;
        width: auto;
        object-fit: contain;
        &.arrow-top {
          display: none;
          ${respondTo.sm`
            display: block;
            `}
        }
        ${respondTo.sm`
            height: 42px;
            `}
      }

      .arrow-top {
        cursor: pointer;
        position: absolute;
        right: 32px;
      }
    }

    &-bottom {
      background-color: #6e6e6e;
      text-align: center;
      padding: 8px 10px 18px 10px;
      border-radius: 0 0 14px 14px;
      font-size: 9px;
      line-height: 18px;
      color: #ffffff;

      span {
        margin-top: 10px;
        display: block;
        line-height: 1.5;
        opacity: 0.8;
        font-weight: 100;
      }

      a {
        color: #ff000f;
        text-decoration: underline;
      }
    }
  }
`;

const Landing = props => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Container>
      <div className='container-full header'>
        <div className='header-content'>
          <h2>
            <span>—</span> Iscriviti ad ABB Premi Plus<small>+</small> 2024. <br />
            Tanti premi ti aspettano!
          </h2>
          <div className='header-btn-registration-wrapper'>
            <Button active>
              <Link to={'/'}>Registrati</Link>
            </Button>
          </div>
        </div>
        <div className='download-app-header'>
          <a
            href='https://apps.apple.com/it/app/abb-premi-plus/id6477866002'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={appIos} alt='App Store' />
          </a>

          <a
            href='https://play.google.com/store/apps/details?id=com.next.abb'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={googlePlay} alt='Google Play' />
          </a>
        </div>
      </div>
      <div className='container-short description'>
        <h4>
          <span>—</span> Partecipa ad ABB Premi Plus<small>+</small> 2024* e guadagna punti
        </h4>

        <p>
          <strong>
            ABB Premi Plus<small>+</small> 2024
          </strong>{' '}
          è l'iniziativa che premia la fedeltà dei professionisti del mondo elettrico e consente di
          accumulare punti da spendere su un ricco catalogo. Ecco alcune delle azioni che puoi fare
          per conquistare i premi che desideri.
        </p>

        <div className='points'>
          <div className='points--item'>
            <img src={iconPoint1} alt='Welcome bonus PLUS' />

            <div>
              <h4>Bonus di Benvenuto Plus</h4>
              <p>
                Carica almeno un DDT tra il 15/03/2024 e il 15/04/2024, e ottieni un Buono Regalo
                Amazon.it*** da 5 euro.
              </p>
            </div>
          </div>

          <div className='points--item'>
            <img src={iconPoint2} alt='News & formazione' />

            <div>
              <h4>News & formazione</h4>
              <p>
                Partecipa ad eventi formativi, scarica il materiale, guarda i video e fai un test
                per accumulare punti velocemente.
              </p>
            </div>
          </div>

          <div className='points--item'>
            <img
              src={iconPoint3}
              alt='Acquista i prodotti ABB'
              style={{ height: '35px', minWidth: '35px', width: 'auto', objectFit: 'fill' }}
            />

            <div>
              <h4>Acquista i prodotti ABB in promozione</h4>
              <p>Carica i DDT e accumula punti nel periodo della promozione.</p>
            </div>
          </div>

          <div className='points--item'>
            <img src={iconPoint4} alt='Member Get Member' />

            <div>
              <h4>Invita altri installatori a partecipare</h4>
              <p>Invita altri installatori a partecipare e guadagna punti extra.</p>
            </div>
          </div>
        </div>

        <p>Tanti premi ti aspettano</p>
        <Carousel className='carousel-landing no-desktop' otherSettings={{ className: ' prizes ' }}>
          <div className='prizes--item'>
            <img src={prizes5} alt='Piquadro' />
          </div>

          <div className='prizes--item'>
            <img src={prizes1} alt='Alpitour World' />
          </div>

          <div className='prizes--item'>
            <img src={prizes2} alt='Decathlon' />
          </div>

          <div className='prizes--item'>
            <img src={prizes3} alt='Eataly' />
          </div>

          <div className='prizes--item'>
            <img src={prizes4} alt='Ip' />
          </div>
        </Carousel>
        <div className='prizes slider carousel-landing no-mobile'>
          <div className='prizes--item'>
            <img src={prizes5} alt='Piquadro' />
          </div>
          <div className='prizes--item'>
            <img src={prizes1} alt='Alpitour World' />
          </div>
          <div className='prizes--item'>
            <img src={prizes2} alt='Decathlon' />
          </div>
          <div className='prizes--item'>
            <img src={prizes3} alt='Eataly' />
          </div>
          <div className='prizes--item'>
            <img src={prizes4} alt='Ip' />
          </div>
        </div>
      </div>

      <div className='container-short description'>
        <h4>
          <span>—</span> Partecipa alle estrazioni di ABB Premi Plus<small>+</small> Concorso
          Installatori**
        </h4>

        <p>
          Inoltre puoi partecipare a 4 estrazioni periodiche e ad una estrazione finale per provare
          a vincere meravigliosi premi! Clicca{' '}
          <a href='https://abbpremiplus.it/app/regolamento-concorso.pdf' target='_blank'>
            QUI
          </a>{' '}
          per scoprire come fare.
        </p>

        <Carousel
          className='carousel-landing no-desktop'
          otherSettings={{ className: ' lottery ' }}
        >
          <div className='lottery--item'>
            <h6>&nbsp;</h6>
            <img src={lottery1} alt='Alpitour World' />
            <p>
              <b>Apple</b> iPhone 15
              <br />
              128 GB
            </p>
          </div>
          <div className='lottery--item'>
            <h6>&nbsp;</h6>
            <img src={lottery2} alt='Decathlon' />
            <p>
              <b>LEXGO</b> MONOPATTINO ELETTRICO L10
            </p>
          </div>
          <div className='lottery--item'>
            <h6>&nbsp;</h6>
            <img src={lottery3} alt='Eataly' />
            <p>
              <b>AMAZON</b> BUONO REGALO AMAZON.IT*** EURO 250
            </p>
          </div>
          <div className='lottery--item'>
            <h6>&nbsp;</h6>
            <img src={lottery4} alt='Ip' />
            <p>
              <b>XP</b> E-BIKE
              <br />
              D6.2
            </p>
          </div>
        </Carousel>
        <div className='lottery slider carousel-landing no-mobile'>
          <div className='lottery--item'>
            <img src={lottery1} alt='' />
            <p>
              <b>Apple</b> iPhone 15
              <br />
              128 GB
            </p>
          </div>
          <div className='lottery--item'>
            <img src={lottery2} alt='' />
            <p>
              <b>LEXGO</b> MONOPATTINO ELETTRICO L10
            </p>
          </div>
          <div className='lottery--item'>
            <img src={lottery3} alt='' />
            <p>
              <b>AMAZON</b> BUONO REGALO AMAZON.IT*** EURO 250
            </p>
          </div>
          <div className='lottery--item'>
            <img src={lottery4} alt='' />
            <p>
              <b>XP</b> E-BIKE
              <br />
              D6.2
            </p>
          </div>
          <div className='lottery--item lottery--item-special'>
            <h6>
              <b>ESTRAZIONE FINALE</b>
            </h6>
            <img src={lottery5} alt='Piquadro' />
            <p>VIAGGIO A ROMA CON VISITA GUIDATA NELLA FABBRICA ABB</p>
          </div>
        </div>
        <div className='lottery--item lottery--item-special for-slider'>
          <h6>
            <b>ESTRAZIONE FINALE</b>
          </h6>
          <img src={lottery5mobile} alt='Piquadro' />
          <p>VIAGGIO A ROMA CON VISITA GUIDATA NELLA FABBRICA ABB</p>
        </div>
      </div>

      <div className='container-short restrictions'>
        ***Restrizioni applicate. Vedere dettagli su:{' '}
        <a href='https://amazon.it/gc-legal' target='_blank' rel='noopener noreferrer'>
          amazon.it/gc-legal
        </a>
      </div>

      <div className='container-full download'>
        <div className='download-wrapper'>
          <h1>
            Partecipa subito! <br />
            Scarica la App
          </h1>

          <div className='download-app'>
            <a
              href='https://apps.apple.com/it/app/abb-premi-plus/id6477866002'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={appIos} alt='App Store' />
            </a>

            <a
              href='https://play.google.com/store/apps/details?id=com.next.abb'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={googlePlay} alt='Google Play' />
            </a>
          </div>
        </div>
      </div>

      <div className='btn-registration-wrapper'>
        <Button active>
          <Link to={'/'}>Registrati</Link>
        </Button>
      </div>

      <div className='email-contact'>
        Hai bisogno di assistenza? Scrivi a{' '}
        <a href='mailto:assistenza@abbpremiplus.it'>assistenza@abbpremiplus.it</a>
      </div>

      <div className='container-short footer'>
        <div className='footer-top'>
          <img src={logoABB} alt='Logo: Abb' />

          <img src={arrowTop} alt='Icon: Arrow top' className='arrow-top' onClick={handleClick} />
        </div>

        <div className='footer-bottom'>
          <span>
            *Operazione riservata agli installatori e al personale dipendente dei distributori,
            valida dal 15/03/2024 al 31/12/2024, con richiesta premi fino al 31/03/2025. Montepremi
            stimato € 300.000,00 + IVA ove dovuta. Regolamento{' '}
            <a href='https://abbpremiplus.it/app/regolamento.pdf' target='_blank'>
              QUI
            </a>
          </span>

          <span>
            **Concorso riservato agli installatori valido dal 01.04.2024 al 30.11.2024 nei seguenti
            periodi: 1° periodo: dal 1° aprile 2024 al 30 aprile 2024 / 2° periodo: dal 1° giugno
            2024 al 30 giugno 2024 / 3 periodo: dal 1° agosto 2024 a 30 settembre 2024 / 4° periodo:
            dal 1° novembre 2024 al 30 novembre 2024. Stima valore montepremi € 15.713,90 + IVA ove
            dovuta. Regolamento{' '}
            <a href='https://abbpremiplus.it/app/regolamento-concorso.pdf' target='_blank'>
              QUI
            </a>
          </span>
        </div>
      </div>
    </Container>
  );
};

Landing.propTypes = {
  type: PropTypes.string,
};

export default Landing;
