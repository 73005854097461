import React, { Suspense, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SessionExpired from '../../components/SessionExpired';
import { Button, Loader, Modal, Text } from '../../components/Ui';
import * as actionCreators from '../../store/actions';
import { respondTo } from '../../theme/mixin';

const MainContent = styled.div`
  flex: 1 0 auto;
  max-width: 1170px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: ${props => props.theme.header_mobile_height + 30}px 15px 0 15px;

  ${respondTo.sm`
		padding-top: 0;
	`}
`;

const BaseLayout = props => {
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    footercontent,
    appConfig: { i18l },
  } = props;
  const wins = useSelector(state => state.user.user?.wins);
  const [isModal, setIsModal] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (wins?.length > 0 && location.pathname !== '/form-win') {
      setIsModal(wins[0].pending);
    }
  }, [wins]);

  let lazy = null;

  const Content = (
    <>
      <MainContent>
        <Header
          isLogged={isLogged}
          logo={logo}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          logout={logout}
          bootApp={bootApp}
          langs={langs}
          oauths={oauths}
          ownLogin={ownLogin}
          multipromo={multipromo}
          oauthInfo={oauthInfo}
          userInfo={userInfo}
          oauth_profile_url={oauth_profile_url}
          i18l={i18l}
        />

        <main>
          <C isLogged={isLogged} />
        </main>
      </MainContent>
      <Footer footercontent={footercontent} />
      <Modal show={isModal} close={() => setIsModal(false)}>
        <Text>Hai un premio da convalidare</Text>
        <Link to='/form-win'>
          <Button primary onClick={() => setIsModal(false)}>
            Convalida
          </Button>
        </Link>
      </Modal>
    </>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return (
    <React.Fragment>
      {Comp}
      <SessionExpired isExpired={isExpired} />
    </React.Fragment>
  );
};

BaseLayout.defaultProps = {
  type: 'base',
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    logoinverted: state.app.config.logoinverted,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    error404: state.promo.error404,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
    appConfig: state.app.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: () => dispatch(actionCreators.logout()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
